<template>
  <el-input
      :placeholder="`${$t('system.enter_text')}...`"
      :size="size"
      v-model="filter.value"
  />
</template>

<script>
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldInput',
  mixins: [filtersGeneratorMixin],
};
</script>

<style scoped>

</style>
